import React, { useRef } from "react"
import { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { css } from '@emotion/react'
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import classNames from "classnames"
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import { Helmet } from "react-helmet"
import { Fade } from "react-awesome-reveal";
import scrollTo from 'gatsby-plugin-smoothscroll';
import {
  isIPad13,
} from "react-device-detect";
import { BgImage } from 'gbimage-bridge';



if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}


//Cursor JS code
export default function Index({ data }) {

  
 const linkRef = useRef(null);
  const [cursorChange, setIsShown] = useState("cursor");

  const Cursor = () => {

    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [clicked, setClicked] = useState(false);
    const [linkHovered, setLinkHovered] = useState(false);
    const [hidden, setHidden] = useState(false);

    useEffect(() => {
        addEventListeners();
        handleLinkHoverEvents();
        return () => removeEventListeners();
    }, []);

    const addEventListeners = () => {
        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseenter", onMouseEnter);
        document.addEventListener("mouseleave", onMouseLeave);
        document.addEventListener("mousedown", onMouseDown);
        document.addEventListener("mouseup", onMouseUp);
    };

    const removeEventListeners = () => {
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseenter", onMouseEnter);
        document.removeEventListener("mouseleave", onMouseLeave);
        document.removeEventListener("mousedown", onMouseDown);
        document.removeEventListener("mouseup", onMouseUp);
    };

    const onMouseMove = (e) => {
        setPosition({ x: e.clientX, y: e.clientY });
    };

    const onMouseDown = () => {
        setClicked(true);
    };

    const onMouseUp = () => {
        setClicked(false);
    };

    const onMouseLeave = () => {
        setHidden(true);
    };

    const onMouseEnter = () => {
        setHidden(false);
    };

    const handleLinkHoverEvents = () => {
        document.querySelectorAll("a").forEach((el) => {
            el.addEventListener("mouseover", () => setLinkHovered(true));
            el.addEventListener("mouseout", () => setLinkHovered(false));
        });
    };

    const cursorClasses = classNames(cursorChange, {
        [cursorChange + "--clicked"]: clicked,
        "cursor--hidden": hidden,
        [cursorChange + "--link-hovered"]: linkHovered
    });

    // Hide cursor on iPadOS
    if (isIPad13) {
      return <div/>
    } else {
    return (
        <div
            className={cursorClasses}
            style={{ left: `${position.x}px`, top: `${position.y}px` }}
        />)}
};

// Hamburger checkbox
const [checked, setChecked] = React.useState(false);

// Hide scroll when Hamburger is checked
if (checked === true) {
  var bodyClass = 'bodytag-overflow'}
  else {
    bodyClass = 'bodytagnooverflow'
  }


// Main layout
return (      <div>
{/* HTML Header content */}
<Helmet
  htmlAttributes={{
    lang: 'nl',
  }}
>
  <body className={bodyClass}/>
  <title>Kinesist Liesbet Vermoesen - Begijnendijk</title>
  <meta name="description" content="Kinepraktijk in Begijnendijk gespecialiseerd in het behandelen van rug- en nekproblemen en pijn door overbelasting bij muzikanten." />
</Helmet>

{/* Cursor div */}
<Cursor/>

{/* Navbar */}
<div id="header" >
  <div id="container" className="wrapper noselect">

  {/* Desktop Nav */}
    <a role="button" onClick={() => scrollTo('#hero')}  id="logo" className="logo-white noselect">
        Kinepraktijk Vermoesen
    </a>
    <div id="nav">
      <a role="button" id="navspec" onClick={() => scrollTo('#specialisaties')} className="navlink noselect">Specialisaties</a>
      <a role="button" id="navabout" onClick={() => scrollTo('#about')}  className="navlink noselect">Over Mij</a>
      <a role="button" id="navcontact" onClick={() => scrollTo('#contact')} className="navlink noselect">Contact</a>
    </div>

  {/* Mobile Nav */}
    <div id="mobilebutton">
        <label for="trigger">
          <div onClick={() => {
                    setChecked(old => !old)
                    }} className="burger"
          >
            <div className="burgerline" />
            <div className="burgerline" />
            <div className="burgerline" />
          </div> 
        </label>
        <input id="trigger" type="checkbox" checked={checked} class="visually-hidden"/>
    {/* Mobile slide-in menu */}
        <div className={(checked === true ? "menu-visible" : "menu")} onMouseEnter={() => setIsShown("cursorblack")} onMouseLeave={() => setIsShown("cursor")}>
    {/* Close button */}
        <div id="container" css={css`padding-top: 1.5rem;float: right;`} className="wrapper noselect">
          <label for="trigger">
            <div onClick={() => {
                    setChecked(old => !old)
                    }} css={css`font-family: "Helvetica Neue", sans-serif; font-size: 8vw; padding-right: 2rem;`}>
                      x
            </div>
          </label>
        </div>
    {/* Mobile Nav pop-over */}
        <div css={css`position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);`}> 
            <Fade className="burgernav" duration="500" direction="down" cascade="true" damping="0.4">
                <div role="button" onClick={() => {
                  setChecked(old => !old)
                  scrollTo('#specialisaties')
                  }}>Specialisaties</div>
                <div  role="button"  onClick={() => {
                  setChecked(old => !old)
                  scrollTo('#about')
                  }}>Over mij</div>
                <div role="button"  onClick={() => {
                  setChecked(old => !old)
                  scrollTo('#contact')
                  }}>Contact</div>
                <div style={{ marginTop: "50%" }}>   
                  <a  href="tel:0477447108">
                    <p css={css`text-decoration: underline; text-decoration-color: var(--accent-color)`}>+32 (0)477 44 71 08</p>
                  </a>
                </div>
                <div>   
                  <a  href="mailto:lsbvrm@scarlet.be">
                    <p css={css`text-decoration: underline; text-decoration-color: var(--accent-color)`}>lsbvrm@scarlet.be</p>
                  </a>
                </div>
                <div>
                  <a  href="https://goo.gl/maps/7XQJ8NYen22WghE4A">
                    <p css={css`text-decoration: underline; text-decoration-color: var(--accent-color)`}>Betekomsesteenweg 43a<br/>3130 Begijnendijk</p>
                  </a>
                </div>
            </Fade>
        </div>
      </div>
    </div>
  </div>
</div>


<Controller refreshInterval="50">
  {/* Sectie 1: Hero */}
  <Fade triggerOnce='true'>
    <Scene indicators={false}
          duration="60%"
          triggerElement="trigger"
          loglevel="0"
    >
      <Timeline wrapper={<div className="parallax" />} >
        <div css={css`background-color: var(--overlay); height: 100%; width: 100%; z-index: 1; position: absolute`}></div>

        <GatsbyImage alt="foto van massage" css={css`width: 100%; height: 100vh; position: absolute;`} image={data.file.childImageSharp.gatsbyImageData}/>
        
        <Tween
          position="0"
          from={{
            top: '0vh',
            opacity: 1,
          }}
          to={{
            top: '-20vh',
            opacity: 0,
          }}
          >
            <div id="hero" className="noselect" >
              <div css={css`color: var(--background-color); `}><h1 className="hero" >Ons lichaam is de weerspiegeling</h1></div>
              <div css={css`color: var(--background-color)`}><h1 className="hero" >van onze ziel</h1></div>
            </div>
        </Tween>
      </Timeline>
    </Scene>
  </Fade>

  {/* Sectie 2: Specialisaties */}
  <Fade triggerOnce='true'>
    <Scene indicators={false}
        duration="100%"
        triggerElement="#specialisaties"
        classToggle={['#navspec', 'active']}
    >
      <div id="specialisaties" className="section" css={css`background-color: var(--accent-color); display: flex; align-content: center`} onMouseEnter={() => setIsShown("cursor")}>
        {data.wp.websiteSettings.websitesettings.specialisaties.map(({ omschrijving, afbeelding }) => {
          const specImage = getImage(afbeelding.localFile.childImageSharp.gatsbyImageData)
          return(<div className="specwrapper">
                  <BgImage className="spec" alt={afbeelding.altText} image={specImage} css={css`height: 100%; width: 100%; display: flex;`}>
                    <div css={css`background-color: rgba(227,108,37, 0.2); height: 100%; width: 100%; display: flex; align-items: flex-end`}>
                      <p css={css`color: var(--background-color); padding-left: 1rem; font-weight: 700; font-size: 2vwmax`}>{omschrijving}</p>
                    </div>
                  </BgImage>
                </div>
          )})}
      </div>
    </Scene>
  </Fade>

  {/* Sectie 3: Over mij */}
      <Scene indicators={false}
        duration="100%"
        triggerElement="#about"
        classToggle={['#navabout', 'active']}
      >
        <div id="about" role="none"  onMouseOver={() => setIsShown("cursorblack")} onFocus={() => setIsShown("cursorblack")}  onMouseEnter={() => setIsShown("cursorblack")} css={css`background-color: var(--background-color)`}>
        <div className="abouttrigger abouttrigger2"/>
          <div className="wrapper flex" css={css`min-height: 100vh; align-items: center`}>
            <div className="abouttext" css={css`flex-basis: 50%`}>
              <Fade direction="up">
                <h2>Welkom bij Kinepraktijk Vermoesen</h2>
                <p>Kinepraktijk Vermoesen bestaat meer dan 20 jaar. Ik ben opgegroeid in Begijnendijk en heb mijn droom gerealiseerd om ooit een eigen praktijk te starten.</p>
                <br/>
                <p>- Liesbet</p>
              </Fade>
            </div>
            <div>
              <GatsbyImage image={data.wp.websiteSettings.websitesettings.overMijFoto.localFile.childImageSharp.gatsbyImageData} css={css``}></GatsbyImage>
            </div>
          </div>
        </div>
      </Scene>

      <Scene indicators={false}
        duration="150%"
        triggerElement=".abouttrigger"
        classToggle={['.navlink, #logo', 'header-black']}
      >
          <div />
      </Scene>

      <Scene indicators={false}
        duration="150%"
        triggerElement=".abouttrigger2"
        classToggle={['.burgerline', 'burgerline-black']}
      >
          <div />
      </Scene>

  {/* Sectie 4: Contact */}
      <Scene 
        duration="100%"
        triggerElement="#contact"
        classToggle={['#navcontact', 'active']}
      >
       <div id="contact" role="none" ref={linkRef} css={css`background-color: var(--background-color)`} onMouseEnter={() => setIsShown("cursorblack")}>
        <div className="wrapper flex noselect" css={css`height: 95%; align-items: center`}>
          <div css={css`flex-basis: 50%`}>
            <Fade direction="up">
              <h2 className="noselect">Kinesist Liesbet Vermoesen</h2>
              <a  href="https://goo.gl/maps/7XQJ8NYen22WghE4A"><p css={css`text-decoration: underline; text-decoration-color: var(--accent-color)`}>Betekomsesteenweg 43a<br/>3130 Begijnendijk</p></a>
              <a  href="tel:0477447108"><p css={css`text-decoration: underline; text-decoration-color: var(--accent-color)`}>+32 (0)477 44 71 08</p></a>
              <a  href="mailto:lsbvrm@scarlet.be"><p css={css`text-decoration: underline; text-decoration-color: var(--accent-color)`}>lsbvrm@scarlet.be</p></a><br/>
              <p>Volledig geconventioneerd</p>
            </Fade>
          </div>
          <div css={css`flex-basis: 50%`} className="contactimagecontainer">
            <GatsbyImage className="contactimage" image={data.wp.websiteSettings.websitesettings.contactFotos[0].localFile.childImageSharp.gatsbyImageData} css={css`position: absolute, bottom: 5rem;`}></GatsbyImage>
            <GatsbyImage className="contactimage" image={data.wp.websiteSettings.websitesettings.contactFotos[1].localFile.childImageSharp.gatsbyImageData} css={css`position: absolute`}></GatsbyImage>
            <GatsbyImage className="contactimage" image={data.wp.websiteSettings.websitesettings.contactFotos[2].localFile.childImageSharp.gatsbyImageData} css={css`position: absolute`}></GatsbyImage>
          </div>
        </div>
        <div css={css`text-align: center;`}><a css={css`color: var(--text-color)`}href="https://op-gemerkt.be">OP-gemerkt</a></div>
      </div>
    </Scene>
      
</Controller>
</div>
)
}


// API call

export const query = graphql`
  query {
  file(name: {eq: "hero"}) {
    childImageSharp {
      gatsbyImageData(
        webpOptions: {quality: 70},
        placeholder: BLURRED, 
        formats: WEBP)
    }
  }
  wp {
    websiteSettings {
      websitesettings {
        contactFotos {
          localFile {
            childImageSharp {
              gatsbyImageData(
              webpOptions: {quality: 100},
              placeholder: BLURRED, 
              formats: WEBP)
            }}
        }
        overMijFoto {
          localFile {
            childImageSharp {
              gatsbyImageData(
              webpOptions: {quality: 100},
              width: 300,
              placeholder: BLURRED, 
              formats: WEBP)
            }}
        }
        specialisaties {
          omschrijving
          afbeelding {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                webpOptions: {quality: 100},
                placeholder: BLURRED, 
                formats: WEBP)
              }
            }
          }
        }
      }
    }
  }
}`